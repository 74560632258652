<template>
  <div>
    <cards-inspecciones
      :origen="'NOCTURNA'"
    />
  </div>
</template>

<script>
import CardsInspecciones from '@/components/Inspecciones/CardsInspeccionesRutas.vue'

export default {
  components: {
    CardsInspecciones,
  },
}
</script>
